import type { GenericEmbed } from '@typeform/embed-react';

import { useAutoAnimate } from '@formkit/auto-animate/react';
import { metrics } from '@sentry/react';
import * as stylex from '@stylexjs/stylex';
import { PopupButton } from '@typeform/embed-react';
import { useMemo, useRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { match } from 'ts-pattern';

import { Icon } from '~/components/SVG';
import { CreateAccountSmall, GraphicDesignerHead, KiiaPersonWithComputer, PhotographerHead, SoundEngineerHead } from '~/components/SVG';
import { Button, ExternalLink, Text } from '~/components/UI';
import { env } from '~/constants/env';
import { darkBackgroundTheme } from '~/styles/themes';

import { checklistStyles, kiiaStyles, styles, testimonialStyles } from './AuthLayoutSidebar.styles';

type Props = {
  variant: 'checklist' | 'kiia' | 'testimonials';
};

export const AuthLayoutSidebar = ({ variant }: Props) => {
  const [ref] = useAutoAnimate();

  return (
    <aside {...stylex.props(styles.sidebar)}>
      <div {...stylex.props(styles.contentWrapper)} ref={ref}>
        {match(variant)
          .with('checklist', () => <Checklist />)
          .with('kiia', () => <Kiia />)
          .with('testimonials', () => <Testimonials />)
          .exhaustive()}
      </div>

      <div {...stylex.props(styles.bottom)} />
    </aside>
  );
};

function Checklist() {
  return (
    <div>
      <CreateAccountSmall sparkles={false} styles={checklistStyles.illustration} />

      <div {...stylex.props(checklistStyles.checklist, darkBackgroundTheme)}>
        {[
          'auth:sidebars.checklist.features.first' as const,
          'auth:sidebars.checklist.features.second' as const,
          'auth:sidebars.checklist.features.third' as const,
          'auth:sidebars.checklist.features.fourth' as const,
        ].map((feature) => (
          <div key={feature} {...stylex.props(checklistStyles.checklistItem)}>
            <Icon name="CheckCircleOutline" size={20} />
            <Text size="small">
              <Trans components={{ insuranceFaqLink: <ExternalLink styles={checklistStyles.insuranceFaqLink} /> }} i18nKey={feature} />
            </Text>
          </div>
        ))}
      </div>
    </div>
  );
}

function Kiia() {
  const ref = useRef<GenericEmbed>();

  const { t } = useTranslation(['auth']);

  return (
    <div>
      <div {...stylex.props(kiiaStyles.textWrapper)}>
        <Text weight="light">
          <Trans components={{ break: <br /> }} i18nKey="auth:sidebars.kiia.text.first" />
        </Text>
        <Text weight="light">{t('auth:sidebars.kiia.text.second')}</Text>
      </div>

      <div {...stylex.props(kiiaStyles.eligibilityTest, darkBackgroundTheme)}>
        <Text size="large" weight="bold">
          {t('auth:sidebars.kiia.eligibilityTest.title')}
        </Text>

        <Text>
          <Trans components={{ break: <br /> }} i18nKey="auth:sidebars.kiia.eligibilityTest.description" />
        </Text>

        <Button
          onClick={() => {
            ref.current?.open();
            metrics.increment('registration_kiia_click');
          }}
          size="narrow"
          styles={kiiaStyles.button}
          variant="secondary"
        >
          {t('auth:sidebars.kiia.eligibilityTest.button')}
        </Button>
      </div>

      <KiiaPersonWithComputer styles={kiiaStyles.illustration} />

      <div {...stylex.props(kiiaStyles.hiddenButton)}>
        <PopupButton
          embedRef={ref}
          enableSandbox={!env.IS_PRODUCTION_ENV}
          id="dcjBYauQ"
          tracking={{
            utm_campaign: 'registratie',
            utm_source: 'craft',
            utm_medium: 'website',
          }}
        />
      </div>
    </div>
  );
}

function Testimonials() {
  const { t } = useTranslation(['auth']);

  const testimonials = useMemo(
    () =>
      [
        { testimonial: t('auth:sidebars.testimonials.joelle', { returnObjects: true }), illustration: 'graphicDesigner' as const },
        { testimonial: t('auth:sidebars.testimonials.toon', { returnObjects: true }), illustration: 'soundEngineer' as const },
        { testimonial: t('auth:sidebars.testimonials.indiana', { returnObjects: true }), illustration: 'photographer' as const },
      ].sort(() => 0.5 - Math.random()),
    [t],
  );

  return (
    <div {...stylex.props(testimonialStyles.wrapper)}>
      {testimonials.map(({ illustration, testimonial }, i) => (
        <div key={i} {...stylex.props(testimonialStyles.testimonialWrapper, i % 2 === 1 && testimonialStyles.testimonialWrapperReverse)}>
          <article {...stylex.props(testimonialStyles.testimonial)}>
            <header {...stylex.props(testimonialStyles.header)}>
              <div {...stylex.props(testimonialStyles.stars)}>
                {Array.from({ length: 5 }).map((_, i) => (
                  <Icon key={i} name="Star" />
                ))}
              </div>

              <Text weight="bold">{testimonial.title}</Text>
            </header>

            <Text size="small">
              {testimonial.body ??
                // i18n cimode
                (typeof testimonial === 'string' && testimonial)}
            </Text>
            <Text size="small" weight="medium">
              {testimonial.name}
            </Text>
          </article>

          {match(illustration)
            .with('graphicDesigner', () => <GraphicDesignerHead styles={testimonialStyles.illustration} />)
            .with('photographer', () => <PhotographerHead styles={testimonialStyles.illustration} />)
            .with('soundEngineer', () => <SoundEngineerHead styles={testimonialStyles.illustration} />)
            .exhaustive()}
        </div>
      ))}
    </div>
  );
}
