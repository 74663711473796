import { Icon } from '~/components/SVG';

import type { SubsectionProps as Props } from './types';

export const Subsection = ({ children, icon, text }: Props) => {
  return (
    <section className="mb-8">
      <div className="bg-primary-50 text-primary-500 px-4 py-2.5 mb-6">
        <Icon inline name={icon} />
        <span className="ml-2">{text}</span>
      </div>

      {children}
    </section>
  );
};
