import * as stylex from '@stylexjs/stylex';

import { styles } from './InputPrefix.styles';

type Props = {
  children: string;
};

export const InputPrefix = ({ children }: Props) => {
  return <div {...stylex.props(styles.prefix)}>{children}</div>;
};
