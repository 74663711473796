import classNames from 'classnames';
import { enGB, fr, nlBE } from 'date-fns/locale';
import Datepicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { useCraftLocalStorage } from '~/hooks/useCraftLocalStorage';

import type { DatePickerProps as Props } from './types';

import './DatePicker.scss';

registerLocale('en', enGB);
registerLocale('fr', fr);
registerLocale('nl', nlBE);

export const DatePicker = ({ isInvalid, minDate, onChange, placeholder = 'dd/mm/yyyy', selected }: Props) => {
  const [language] = useCraftLocalStorage('language');

  return (
    <Datepicker
      calendarStartDay={1}
      className={classNames(isInvalid && 'is-invalid')}
      dateFormat="dd/MM/yyyy"
      locale={language}
      minDate={minDate}
      onChange={onChange}
      placeholderText={placeholder}
      selected={selected}
      strictParsing
    />
  );
};
