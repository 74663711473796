import type { ReactNode } from 'react';

import * as stylex from '@stylexjs/stylex';

import type { WithoutStyleProps } from '~/components/types';

import { sizeStyles, styles, weightStyles } from './Text.styles';

type Props = WithoutStyleProps<{
  balance?: boolean;
  children?: ReactNode;
  noLineHeight?: boolean;
  size?: 'tiny' | 'small' | 'regular' | 'large' | 'xlarge';
  styles?: stylex.StyleXStyles;
  weight?: 'light' | 'regular' | 'medium' | 'semiBold' | 'bold';
}>;

export const Text = ({
  balance = false,
  children,
  noLineHeight = false,
  size = 'regular',
  styles: styleOverrides,
  weight = 'regular',
}: Props) => {
  return (
    <p
      {...stylex.props(
        styles.text,
        sizeStyles[size],
        weightStyles[weight],
        noLineHeight && styles.noLineHeight,
        balance && styles.balance,
        styleOverrides,
      )}
    >
      {children}
    </p>
  );
};
