import type { ReactNode } from 'react';

import * as stylex from '@stylexjs/stylex';

import { styles } from './Span.styles';
import { sizeStyles, weightStyles } from './Text.styles';

type Props = {
  children?: ReactNode;
  noLineHeight?: boolean;
  size?: 'tiny' | 'small' | 'regular' | 'large' | 'xlarge';
  weight?: 'light' | 'regular' | 'medium' | 'semiBold' | 'bold';
};

export const Span = ({ children, noLineHeight, size, weight }: Props) => {
  return (
    <span {...stylex.props(styles.span, size && sizeStyles[size], weight && weightStyles[weight], noLineHeight && styles.noLineHeight)}>
      {children}
    </span>
  );
};
