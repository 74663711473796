import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { useConditions } from '~/api/conditions';
import { useUpdateUser, useUser } from '~/api/user';
import { Button, FormControl, FormErrorMessage, FormLabel, FormSelect, FormTextInput, Modal, Subsection } from '~/components';
import { Language } from '~/types/app';
import { MainOccupation } from '~/types/user';

import type { EditProfileInfoFormType, ProfileInfoModalProps as Props } from './types';

export const ProfileInfoModal = ({ onClose }: Props) => {
  const { data: user } = useUser();
  const mutation = useUpdateUser();
  const { refetch: refetchConditions } = useConditions();

  const { i18n, t } = useTranslation(['common', 'settings', 'validation']);

  const { control, handleSubmit } = useForm<EditProfileInfoFormType>({
    defaultValues: {
      firstName: user.firstName,
      lastName: user.lastName,
      vatID: user.vatID,
      lang: user.lang,
      mainOccupation: user.mainOccupation,
      companyName: user.companyName,
      phone: user.phone,
      email: user.email,
      address: user.address,
    },
  });

  // Reset language to original on unmount
  useEffect(() => {
    return () => {
      i18n.changeLanguage(user.lang);
    };
  }, [i18n, user.lang]);

  const onSubmit = handleSubmit((data) => {
    mutation.mutate(
      {
        firstName: data.firstName,
        lastName: data.lastName,
        lang: data.lang,
        mainOccupation: data.mainOccupation,
        phone: data.phone,
      },
      {
        onSuccess: (updatedUser) => {
          toast.success(t('settings:alerts.successUpdated'));

          // Refetch the conditions if user language has changed
          if (user.lang !== updatedUser.lang) refetchConditions();

          onClose();
        },
        onError: () => toast.error(t('common:error')),
      },
    );
  });

  return (
    <Modal onClose={onClose} variant="wide">
      <Modal.Title>{t('settings:profile.info.title')}</Modal.Title>

      <form onSubmit={onSubmit}>
        <Subsection icon="Person" text={t('settings:profile.info.subsections.personal')}>
          <div className="form-grid-2">
            {/* First name */}
            <FormControl control={control} name="firstName" rules={{ required: true, maxLength: 255 }}>
              <FormLabel>{t('settings:fields.firstName.label')}</FormLabel>
              <FormTextInput placeholder={t('settings:fields.firstName.placeholder')} />
              <FormErrorMessage
                maxLength={t('validation:maxLength', { attribute: t('settings:fields.firstName.label'), max: 255 })}
                required={t('validation:required')}
              />
            </FormControl>

            {/* Last name */}
            <FormControl control={control} name="lastName" rules={{ required: true, maxLength: 255 }}>
              <FormLabel>{t('settings:fields.lastName.label')}</FormLabel>
              <FormTextInput placeholder={t('settings:fields.lastName.placeholder')} />
              <FormErrorMessage
                maxLength={t('validation:maxLength', { attribute: t('settings:fields.lastName.label'), max: 255 })}
                required={t('validation:required')}
              />
            </FormControl>

            {/* Vat number */}
            <FormControl control={control} name="vatID">
              <FormLabel>{t('settings:fields.vatID.label')}</FormLabel>
              <FormTextInput disabled />
            </FormControl>

            {/* Language */}
            <FormControl control={control} name="lang" rules={{ required: true }}>
              <FormLabel>{t('settings:fields.lang.label')}</FormLabel>
              <FormSelect
                onAfterChange={({ newValue }) => newValue && i18n.changeLanguage(newValue)}
                options={([Language.DUTCH, Language.FRENCH] as const).map((language) => ({
                  value: language,
                  label: t(`common:languages.${language}`),
                }))}
              />
              <FormErrorMessage required={t('validation:required')} />
            </FormControl>

            {/* Main occupation */}
            <FormControl control={control} name="mainOccupation" rules={{ required: true }}>
              <FormLabel>{t('settings:fields.mainOccupation.label')}</FormLabel>
              <FormSelect
                options={([MainOccupation.YES, MainOccupation.NO] as const).map((mainOccupation) => ({
                  value: mainOccupation,
                  label: t(`settings:fields.mainOccupation.options.${mainOccupation}`),
                }))}
              />
              <FormErrorMessage required={t('validation:required')} />
            </FormControl>

            {/* Company name */}
            <FormControl control={control} name="companyName">
              <FormLabel>{t('settings:fields.companyName.label')}</FormLabel>
              <FormTextInput disabled />
            </FormControl>

            {/* Phone number */}
            <FormControl control={control} name="phone" rules={{ required: true, maxLength: 255 }}>
              <FormLabel>{t('settings:fields.phone.label')}</FormLabel>
              <FormTextInput placeholder={t('settings:fields.phone.placeholder')} />
              <FormErrorMessage
                maxLength={t('validation:maxLength', { attribute: t('settings:fields.phone.label'), max: 255 })}
                required={t('validation:required')}
              />
            </FormControl>

            {/* Email */}
            <FormControl control={control} name="email">
              <FormLabel>{t('settings:fields.email.label')}</FormLabel>
              <FormTextInput disabled />
            </FormControl>
          </div>
        </Subsection>

        {/* Address */}
        <Subsection icon="Home" text={t('settings:profile.info.subsections.address')}>
          <div className="form-grid-6">
            {/* Street */}
            <div className="lg:col-span-3">
              <FormControl control={control} name="address.street">
                <FormLabel>{t('settings:fields.street.label')}</FormLabel>
                <FormTextInput disabled />
              </FormControl>
            </div>

            {/* Number */}
            <FormControl control={control} name="address.number">
              <FormLabel>{t('settings:fields.number.label')}</FormLabel>
              <FormTextInput disabled />
            </FormControl>

            {/* Box */}
            <FormControl control={control} name="address.box">
              <FormLabel>{t('settings:fields.box.label')}</FormLabel>
              <FormTextInput disabled />
            </FormControl>

            {/* Annex */}
            <FormControl control={control} name="address.annex">
              <FormLabel>{t('settings:fields.annex.label')}</FormLabel>
              <FormTextInput disabled />
            </FormControl>

            {/* Zip code */}
            <div className="lg:col-span-3">
              <FormControl control={control} name="address.zipcode">
                <FormLabel>{t('settings:fields.zipcode.label')}</FormLabel>
                <FormTextInput disabled />
              </FormControl>
            </div>

            {/* City */}
            <div className="lg:col-span-3">
              <FormControl control={control} name="address.city">
                <FormLabel>{t('settings:fields.city.label')}</FormLabel>
                <FormTextInput disabled />
              </FormControl>
            </div>
          </div>
        </Subsection>

        <div className="flex flex-row justify-end">
          <Button hasSpinner icon="Send" isLoading={mutation.isPending} isSubmit>
            {t('settings:edit')}
          </Button>
        </div>
      </form>
    </Modal>
  );
};
