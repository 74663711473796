import * as stylex from '@stylexjs/stylex';

import type { IconName } from '~/components/SVG';

import { Icon } from '~/components/SVG';

import { styles } from './InputIcon.styles';

type Props = {
  invalid?: boolean;
  name: IconName;
};
export const InputIcon = ({ invalid = false, name }: Props) => {
  return (
    <div {...stylex.props(styles.icon, invalid && styles.invalid)}>
      <Icon name={name} size={22} />
    </div>
  );
};
