import { Outlet } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { useAppColorTheme } from '~/hooks/useAppColorTheme';
import { useAppLanguage } from '~/hooks/useAppLanguage';
import { useForceRefresh } from '~/hooks/useForceRefresh';
import '~/styles/app.scss';

import { AppCommandMenuItems } from './providers/CommandMenuProvider/AppCommandMenuItems';

export const App = () => {
  useAppColorTheme();
  useAppLanguage();
  useForceRefresh();

  return (
    <>
      <Outlet />
      <ToastContainer autoClose={10000} limit={3} pauseOnFocusLoss={false} position="top-center" />

      <AppCommandMenuItems />
    </>
  );
};
