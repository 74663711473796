import * as stylex from '@stylexjs/stylex';
import { useTranslation } from 'react-i18next';

import type { ColorTheme } from '~/api/user/types';

import { Listbox, ListboxOption } from '~/components/Form';
import { CreativeShelterHorizontal } from '~/components/SVG';
import { ColorPicker, ExternalLink } from '~/components/UI';
import { useActiveColorTheme } from '~/hooks/useAppColorTheme';
import { useCraftLocalStorage } from '~/hooks/useCraftLocalStorage';
import { Language } from '~/types/app';

import { styles } from './AuthLayoutHeader.styles';

const craftToWebflowColorTheme = {
  'bordeaux-gray': 'primary',
  'green-pink': 'secondary',
  'blue-orange': 'tertiary',
  'purple-yellow': 'quaternary',
  'red-lightblue': 'quinary',
} satisfies Record<ColorTheme, string>;

const availableLanguages = [Language.DUTCH, Language.FRENCH] as const;

export const AuthLayoutHeader = () => {
  const colorTheme = useActiveColorTheme();
  const [, setColorTheme] = useCraftLocalStorage('colorTheme');
  const [language, setLanguage] = useCraftLocalStorage('language');

  const { i18n } = useTranslation(['auth', 'common']);

  return (
    <div {...stylex.props(styles.header)}>
      <ExternalLink href={`https://www.creativeshelter.be?color-mode=${craftToWebflowColorTheme[colorTheme]}`} styles={styles.logoLink}>
        <CreativeShelterHorizontal styles={styles.logo} />
      </ExternalLink>

      <ColorPicker onSelect={setColorTheme} value={colorTheme} />

      <Listbox
        onChange={(language: Language) => setLanguage(language)}
        styles={{ button: styles.languageListboxButton, options: styles.languageListboxOptions }}
        value={language}
      >
        {availableLanguages.map((language) => (
          <ListboxOption key={language} value={language}>
            <span {...stylex.props(styles.languageFull)}>{i18n.getFixedT(language)(`common:languages.${language}`)}</span>
            <span {...stylex.props(styles.languageShort)}>{i18n.getFixedT(language)(`common:languagesShort.${language}`)}</span>
          </ListboxOption>
        ))}
      </Listbox>
    </div>
  );
};
