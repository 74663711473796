import { useMemo, useSyncExternalStore } from 'react';

import type { ColorTheme } from '~/api/user/types';
import type {
  RegistrationAccountInformation,
  RegistrationBusinessInformation,
  RegistrationReferralCodeInformation,
  RegistrationVatInformation,
} from '~/pages/Auth/Registration/Registration.context';

import { Language } from '~/types/app';

type CraftLocalStorage = {
  colorTheme: ColorTheme | null;
  language: Language;
  registrationAccountInformation: Omit<RegistrationAccountInformation, 'password'> | null;
  registrationBusinessInformation: RegistrationBusinessInformation | null;
  registrationReferralCodeInformation: RegistrationReferralCodeInformation | null;
  registrationVatInformation: RegistrationVatInformation | null;
  sidebar: 'expanded' | 'collapsed';
};

const createKey = (key: string) => `craft-${key}`;

// Initialize local storage
Object.entries({
  sidebar: 'expanded',
  colorTheme: null,
  language: Language.DUTCH,
  registrationVatInformation: null,
  registrationAccountInformation: null,
  registrationBusinessInformation: null,
  registrationReferralCodeInformation: null,
} satisfies CraftLocalStorage).forEach(([key, value]) => {
  const item = window.localStorage.getItem(createKey(key));

  if (
    item === null ||
    // `sidebar` used to be stored without stringifying, which causes issues when trying to parse it.
    // 'expanded' should become '"expanded"' so it is parsable (same for 'collapsed').
    item === 'expanded' ||
    item === 'collapsed'
  )
    window.localStorage.setItem(createKey(key), JSON.stringify(value));
});

// Curried getter and setter
const getItem =
  <K extends keyof CraftLocalStorage>(key: K) =>
  () => {
    return window.localStorage.getItem(createKey(key));
  };

const setItem =
  <K extends keyof CraftLocalStorage>(key: K) =>
  (newValue: CraftLocalStorage[K] | ((oldValue: CraftLocalStorage[K]) => CraftLocalStorage[K])) => {
    const oldValue = window.localStorage.getItem(createKey(key));
    const parsedOldValue = (oldValue !== null ? JSON.parse(oldValue) : oldValue) as CraftLocalStorage[K];

    const stringifiedValue = JSON.stringify(typeof newValue === 'function' ? newValue(parsedOldValue) : newValue);
    window.localStorage.setItem(createKey(key), stringifiedValue);

    // Manually dispatch storage event as it is normally only triggered on other tabs and windows.
    window.dispatchEvent(new StorageEvent('craft-storage', { key, newValue: stringifiedValue }));
  };

// Store subscription
const subscribe = (listener: () => void) => {
  window.addEventListener('craft-storage', listener);
  return () => window.removeEventListener('craft-storage', listener);
};

export const useCraftLocalStorage = <K extends keyof CraftLocalStorage>(key: K) => {
  const getSnapshot = useMemo(() => getItem(key), [key]);
  const value = useSyncExternalStore(subscribe, getSnapshot);
  const setValue = useMemo(() => setItem(key), [key]);

  const parsedValue = useMemo(() => (value !== null ? JSON.parse(value) : value) as CraftLocalStorage[K], [value]);
  return [parsedValue, setValue] as const;
};
