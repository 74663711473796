import { z } from 'zod';

const envSchema = z.object({
  API_URL: z.string().min(1),
  SENTRY_DSN: z.string().optional(),
  PRODUCT_FRUITS_WORKSPACE_CODE: z.string().optional(),
  AMPLITUDE_API_KEY: z.string().optional(),

  /**
   * The mode that Vite is running in. This controls which env file will be loaded.\
   * Defaults to 'development' for `vite serve` and 'production' for `vite build`.\
   * Can be overridden by passing `--mode` to the command.
   */
  VITE_MODE: z.string(),
  /** Vercel's environment ('development' is not used). */
  VERCEL_ENVIRONMENT: z.enum(['production', 'preview', 'development']).optional(),
  /** Whether the application is being served from Vercel's production environment. */
  IS_PRODUCTION_ENV: z.boolean(),
  /** Whether the application is being served from Vercel's preview environment (this includes staging). */
  IS_STAGING_ENV: z.boolean(),
  /** Whether the application is being served from Vite's dev server. */
  IS_LOCAL_DEV_ENV: z.boolean(),

  KBO_UNAVAILABLE: z.boolean(),
});

export const env = envSchema.parse({
  API_URL: import.meta.env.VITE_API_URL,
  SENTRY_DSN: import.meta.env.VITE_SENTRY_DSN || undefined,
  PRODUCT_FRUITS_WORKSPACE_CODE: import.meta.env.VITE_PRODUCT_FRUITS_WORKSPACE_CODE || undefined,
  AMPLITUDE_API_KEY: import.meta.env.VITE_AMPLITUDE_API_KEY || undefined,

  VITE_MODE: import.meta.env.MODE,
  VERCEL_ENVIRONMENT: import.meta.env.VITE_VERCEL_ENV,
  IS_PRODUCTION_ENV: import.meta.env.VITE_VERCEL_ENV === 'production',
  IS_STAGING_ENV: import.meta.env.VITE_VERCEL_ENV === 'preview' && import.meta.env.VITE_VERCEL_GIT_COMMIT_REF === 'staging',
  IS_LOCAL_DEV_ENV: import.meta.env.DEV,

  KBO_UNAVAILABLE: import.meta.env.VITE_KBO_UNAVAILABLE === '1',
});
