import type { InputProps } from '@headlessui/react';
import type { ForwardedRef } from 'react';

import { Input as HuiInput } from '@headlessui/react';
import * as stylex from '@stylexjs/stylex';
import { forwardRef } from 'react';

import type { IconName } from '~/components/SVG';

import { Flex } from '~/components/UI';

import type { WithoutStyleProps } from '../../types';
import type { WithTypedValueProps } from '../types';

import { InputButton } from '../_shared/InputButton';
import { InputIcon } from '../_shared/InputIcon';
import { InputPrefix } from '../_shared/InputPrefix';
import { InputWrapper } from '../_shared/InputWrapper';
import { useClearableInput, usePasswordInput } from './Input.hooks';
import { styles } from './Input.styles';

type Props = WithTypedValueProps<WithoutStyleProps<InputProps>, string> & {
  clearable?: boolean;
  icon?: IconName;
  prefix?: string;
  transformValue?: (value: string) => string;
};

export const Input = forwardRef(({ clearable, icon, prefix, transformValue, ...delegated }: Props, ref: ForwardedRef<HTMLInputElement>) => {
  const { invalid, onChange, type } = delegated;

  const iconName = invalid ? 'ErrorOutline' : icon;

  const { onClear } = useClearableInput(onChange);
  const { isPasswordInput, isPasswordVisible, togglePasswordVisible } = usePasswordInput(type === 'password');

  const hasButtons = clearable || isPasswordInput;

  return (
    <InputWrapper hasPaddingLeft={!iconName} hasPaddingRight={!hasButtons}>
      {iconName && <InputIcon invalid={invalid} name={iconName} />}

      {prefix && <InputPrefix>{prefix}</InputPrefix>}

      <HuiInput
        {...stylex.props(styles.input, isPasswordInput && !isPasswordVisible && styles.passwordInput)}
        {...delegated}
        onChange={(e) => {
          const value = transformValue?.(e.target.value) ?? e.target.value;
          onChange?.(value);
        }}
        ref={ref}
        type={isPasswordVisible ? 'text' : type}
      />

      {hasButtons && (
        <Flex>
          {clearable && <InputButton iconName="Close" onClick={onClear} />}
          {isPasswordInput && <InputButton iconName={isPasswordVisible ? 'VisibilityOff' : 'Visibility'} onClick={togglePasswordVisible} />}
        </Flex>
      )}
    </InputWrapper>
  );
});

Input.displayName = 'Input';
