import classNames from 'classnames';
import dayjs from 'dayjs';
import { useMemo } from 'react';

import { useInvoices } from '~/api/invoices';
import { filterBoolean } from '~/utils/arrays';

import type { ChartWrapperProps as Props } from './types';

import { Select } from '../Form_old/FormFields/Select/Select';
import styles from './ChartWrapper.module.scss';

export const ChartWrapper = ({ BigLegend, children, setYear, title, year }: Props) => {
  const { data: invoices } = useInvoices();

  const yearOptions = useMemo(
    () =>
      [...new Set([dayjs().year(), ...invoices.map((invoice) => invoice.docYear)])].filter(filterBoolean).map((docYear) => ({
        value: docYear,
        label: `${docYear}`,
      })),
    [invoices],
  );

  return (
    <div className={styles.wrapper}>
      <div className={styles.heading}>
        <h4 className={styles.title}>{title}</h4>
        {yearOptions?.length > 1 && (
          <div>
            <Select onChange={(value) => (value ? setYear(Number(value)) : dayjs().year())} options={yearOptions} value={year} />
          </div>
        )}
      </div>
      <div className={classNames({ [styles['has-legend']]: !!BigLegend })}>
        {!!BigLegend && BigLegend}
        <div className={classNames(styles.chart, { [styles.smaller]: !!BigLegend })}>{children}</div>
      </div>
    </div>
  );
};
