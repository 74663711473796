import * as stylex from '@stylexjs/stylex';

import { spacing } from '~/styles/tokens.stylex';

export const styles = stylex.create({
  prefix: {
    paddingInlineEnd: spacing.xxsmall,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});
