export const auth = {
  activationPage: {
    loading: 'Account activeren...',
  },
  footer: {
    socialLinks: {
      facebook: 'https://www.facebook.com/creativeshelter/',
      instagram: 'https://www.instagram.com/creativeshelter/',
      linkedIn: 'https://be.linkedin.com/company/creative-shelter/',
      website: 'https://www.creativeshelter.be/',
    },
    trademark: 'Creative Shelter © {{year}}',
  },
  forgotPasswordPage: {
    errors: {
      ineligible: {
        description:
          'Je kan geen nieuw wachtwoord aanvragen zolang je account niet is goedgekeurd. We zijn je profiel aan het nakijken en laten je snel iets weten.',
        title: 'Wachten op goedkeuring',
      },
      rejected: {
        description:
          'We hebben je profiel nagekeken en gezien dat je helaas niet in aanmerking komt om te factureren via Creative Shelter. We hebben daarom je account gedeactiveerd.',
        title: 'Je komt niet in aanmerking',
      },
      unverifiedEmail: {
        description: 'Je kan geen nieuw wachtwoord aanvragen zolang je e-mailadres niet geverifieerd is. Check je je mailbox even?',
        title: 'Wachten op verificatie',
      },
    },
    form: {
      submitButton: 'Wijzig wachtwoord',
    },
    header: {
      pill: 'Oeps, vergeten',
      subtitle: 'Vraag hier een nieuw wachtwoord aan.',
      title: 'Wachtwoord vergeten',
    },
    loginLink: 'Inloggen',
    successAlert: {
      description:
        'De informatie om je wachtwoord opnieuw in te stellen is naar het opgegeven e-mailadres verzonden. Als je geen mail ontvangt, controleer dan of je het juiste e-mailadres hebt ingevuld.',
      title: 'Mail verzonden!',
    },
    tokenExpiredAlert: {
      description: 'Vraag hier een nieuwe link aan om je wachtwoord te wijzigen.',
      title: 'Link verlopen',
    },
  },
  loginPage: {
    accountantLoginCta: {
      action: '<accountantLink href="https://api.creativeshelter.be/accountants/login">Log je hier in</accountantLink>',
      message: 'Ben je een accountant?',
    },
    errors: {
      ineligible: {
        description:
          'Je kan je nog niet inloggen omdat je niet automatisch in aanmerking komt. We zijn je profiel aan het nakijken en laten je snel iets weten.',
        title: 'Wachten op goedkeuring',
      },
      invalidEmailOrPassword: 'Oeps! Hier ging iets mis, je e-mailadres en/of wachtwoord zijn niet juist.',
      rejected: {
        description:
          'We hebben je profiel nagekeken en gezien dat je helaas niet in aanmerking komt om te factureren via Creative Shelter. We hebben daarom je account gedeactiveerd.',
        title: 'Je komt niet in aanmerking',
      },
      unverifiedEmail: {
        description: 'Je e-mailadres werd nog niet geverifieerd. Check je je mailbox even?',
        title: 'Wachten op verificatie',
      },
    },
    forgotPasswordLink: 'Wachtwoord vergeten?',
    form: {
      emailField: {
        label: 'E-mailadres',
      },
      passwordField: {
        label: 'Wachtwoord',
      },
      submitButton: 'Login',
    },
    header: {
      pill: 'Login',
      subtitle: 'Log in om verder te gaan naar Craft.',
      title: 'Welkom terug',
    },
    registerCta: {
      action: 'Registreer',
      message: 'Heb je nog geen account?',
    },
    sessionExpiredAlert: {
      description: 'Meld je opnieuw aan om verder te kunnen gaan.',
      title: 'Sessie verlopen',
    },
  },
  registrationPage: {
    common: {
      header: {
        pill: 'Registratie',
      },
      loginCta: {
        action: 'Inloggen',
        message: 'Heb je al een account?',
      },
      previousStepButton: 'Vorige',
      referralCode: {
        addButton: 'Kortingscode',
        applied: 'Korting!',
        editButton: 'Wijzigen',
        form: {
          invitationsField: {
            description:
              'We zien dat je door verschillende freelancers bent uitgenodigd. Kies welke uitnodiging je wilt accepteren, of vul zelf een (andere) kortingscode in.',
            invitedAt: 'Uitgenodigd op {{date}}',
          },
          referralCodeField: {
            errors: {
              expired: 'Deze kortingscode is verlopen.',
              generic: 'De kortingscode kan momenteel niet gevalideerd worden.',
              invalid: 'Deze kortingscode is niet geldig.',
            },
            invitationDescription: 'Je bent uitgenodigd door {{name}} met kortingscode <code>{{code}}</code>.',
            label: 'Kortingscode',
          },
          submitButton: 'Toepassen',
        },
        types: {
          fromAccountant: 'De kortingscode {{referralCode}} van accountant {{name}} is toegepast.',
          fromAdmin: {
            ZOMER2024: 'De kortingscode {{referralCode}} is toegepast. Test Creative Shelter uit door 60 dagen gratis te factureren.',
            generic: 'De kortingscode {{referralCode}} is toegepast.',
          },
          fromUser: 'De kortingscode {{referralCode}} van {{name}} is toegepast. Maak je account aan en ontvang allebei korting!',
        },
      },
    },
    stepAccountInformation: {
      alertNoElibibleNacebelCodes: {
        description:
          'Je kan verder gaan met registreren, maar we zullen eerst nog een manuele controle doen voordat we je account activeren.',
        title: 'Je komt niet automatisch in aanmerking',
      },
      form: {
        emailField: {
          errors: {
            exists: 'Dit e-mailadres is al in gebruik, controleer je dit even?',
            generic:
              'Er is iets misgelopen bij het valideren van je e-mailadres. Neem contact op met <email href="mailto:service@creativeshelter.be">service@creativeshelter.be</email> als het probleem zich blijft voordoen.',
          },
          label: 'E-mailadres',
        },
        passwordField: {
          description: 'Kies een sterk wachtwoord van minstens 8 tekens.',
          label: 'Wachtwoord',
        },
        phoneNumberField: {
          label: 'Telefoonnummer',
        },
        submitButton: 'Volgende',
      },
      header: {
        subtitle: 'Laten we aan de slag gaan.',
        title: 'Hey {{name}}',
      },
    },
    stepBusinessInformation: {
      alertItDevelopers: {
        description:
          'Vanaf 2024 kan je geen vergoeding voor auteursrechten meer ontvangen voor computerprogramma prestaties. Voor andere creatieve activiteiten kom je wel nog steeds in aanmerking.',
        title: 'Belangrijk! IT-ontwikkelaar, lees je even verder?',
      },
      errors: {
        fallback: 'Er is een onverwachte fout opgetreden tijdens het registreren.',
        httpError:
          'Er is iets misgelopen tijdens het registreren. Neem contact op met <email href="mailto:service@creativeshelter.be">service@creativeshelter.be</email> als het probleem zich blijft voordoen.',
      },
      form: {
        mainOccupationField: {
          label: 'Statuut',
          options: {
            mainOccupation: 'Hoofdberoep',
            secondaryOccupation: 'Bijberoep',
          },
        },
        otherActivitiesField: {
          label: 'Beschrijf je extra (creatieve) activiteiten',
        },
        primaryActivityField: {
          label: 'Hoofdactiviteit',
        },
        secondaryActivityField: {
          label: 'Heb je nog andere activiteiten?',
        },
        submitButton: 'Maak account aan',
      },
      header: {
        subtitle: 'Vertel wat over je eenmanszaak.',
        title: 'Hey {{name}}',
      },
    },
    stepCompany: {
      alert: {
        description:
          'Ons Craft-platform is gemaakt voor eenmanszaken, en is dus niet van toepassing voor jou. Gelukkig biedt Creative Shelter ook een oplossing voor vennootschappen.<break/>Interesse? <kiiaLink href="https://www.creativeshelter.be/kom-ik-in-aanmerking">Check of je in aanmerking komt.</kiiaLink>',
        title: 'Creative Shelter voor vennootschappen',
      },
      changeVatNumber: {
        action: 'Aanpassen',
        message: 'Niet het juiste btw-nummer?',
      },
      header: {
        subtitle: 'We zien dat je een vennootschap hebt.',
        title: 'Hey, bestuurder van {{name}}',
      },
    },
    stepSuccess: {
      header: {
        subtitle: {
          eligible: 'Je account is succesvol aangemakt.',
          ineligible: 'Je gegevens zijn goed ontvangen.',
        },
        title: 'Klaar!',
      },
      linkAccountant: {
        errors: {
          generic: 'Er is iets misgelopen bij het linken van je accountant.',
        },
        form: {
          accountantEmailAddressField: {
            description:
              'Geef hieronder het e-mailadres van je accountant in. Als je accountant nog niet in ons systeem is opgenomen, nemen we contact op om Creative Shelter voor te stellen.',
            label: 'Heb je al een accountant?',
          },
        },
        success: {
          message: 'We hebben de gegevens van je accountant goed ontvangen en gaan er zo mee aan de slag.',
          title: 'Bedankt voor de info!',
        },
      },
      message: {
        eligible:
          'Er is een verificatiemail verzonden naar <bold>{{email}}</bold>. Volg de instructies in de e-mail om je account te activeren.',
        ineligible:
          'We kijken nog even na of je in aanmerking komt om te factureren via Creative Shelter.<break/>Je mag dit venster sluiten, we laten je snel iets weten via mail!',
      },
    },
    stepVatNumber: {
      alert: {
        kboNotAvailable: {
          description:
            'Wegens problemen bij het KBO kunnen we op dit moment geen btw-nummers valideren. Gelieve even te wachten of later opnieuw te proberen.<break />Onze excuses voor het ongemak!',
          title: 'KBO niet bereikbaar',
        },
      },
      form: {
        submitButton: "Let's go!",
        vatNumberField: {
          description:
            'Heb je nog geen btw-nummer? <faqLink href="https://www.creativeshelter.be/knowledge-hub/kan-ik-mij-al-aansluiten-bij-creative-shelter-als-mijn-btw-nummer-nog-in-aanvraag-is">Lees hier</faqLink> wat je moet doen.',
          errors: {
            exists: 'Dit btw-nummer is al in gebruik, controleer je dit even?',
            fallback: 'Er is een onverwachte fout opgetreden tijdens het valideren van je btw-nummer.',
            httpError:
              'Er is iets misgelopen bij het valideren van je btw-nummer. Neem contact op met <email href="mailto:service@creativeshelter.be">service@creativeshelter.be</email> als het probleem zich blijft voordoen.',
            inactiveVatNumber: 'De opgegeven eenmanszaak is niet actief volgens het KBO.',
            invalidVatNumber: 'Het opgegeven btw-nummer bestaat niet in het KBO. Controleer je het even?',
            vatNumberChecksum: 'Het btw-nummer lijkt niet te kloppen. Check je even of er geen fout in staat?',
            vatNumberLength: 'Een btw-nummer bestaat uit 10 cijfers, waarvan het eerste cijfer 0 of 1 is.',
          },
          label: 'Btw-nummer',
        },
      },
      header: {
        subtitle: 'Maak hier je gratis account aan',
        title: 'Haal meer uit je auteursrechten',
      },
    },
  },
  resendVerificationEmail: {
    error: 'Er is iets misgelopen bij het versturen van de verificatie e-mail. Neem contact op met service@creativeshelter.be.',
    send: 'Niks ontvangen? <button>Klik hier</button> om de verificatiemail opnieuw te versturen.',
    sent: 'We hebben een nieuwe verificatiemail verstuurd naar <bold>{{emailAddress}}</bold>. Nog steeds niks ontvangen? Probeer opnieuw na {{seconds}} seconden.',
  },
  resetPasswordPage: {
    errors: {
      ineligible: {
        description:
          'Je kan je wachtwoord niet wijzigen zolang je account niet is goedgekeurd. We zijn je profiel aan het nakijken en laten je snel iets weten.',
        title: 'Wachten op goedkeuring',
      },
      unverifiedEmail: {
        description: 'Je kan je wachtwoord niet wijzigen zolang je e-mailadres niet geverifieerd is. Check je je mailbox even?',
        title: 'Wachten op verificatie',
      },
    },
    form: {
      newPasswordField: {
        label: 'Nieuw wachtwoord',
      },
      submitButton: 'Wijzig wachtwoord',
    },
    header: {
      subtitle: 'Kies een nieuw wachtwoord voor je Craft-account.',
      title: 'Wachtwoord resetten',
    },
  },
  sidebars: {
    checklist: {
      features: {
        first: 'Je auteursrechten geregeld, snel en efficiënt via het Craft-platform.',
        fourth: 'Topservice? Standaard inbegrepen bij Creative Shelter!',
        second: 'Verdien meer aan je creatieve facturen, zonder gedoe.',
        third:
          'Bij ons zit je <insuranceFaqLink href="https://www.creativeshelter.be/knowledge-hub/hoe-ben-ik-verzekerd-bij-creative-shelter">driedubbel verzekerd</insuranceFaqLink>.',
      },
    },
    kiia: {
      eligibilityTest: {
        button: 'Doe de check',
        description: 'Bij zo’n voordeel horen wel wat voorwaarden.<break />Niet zeker of je hieraan voldoet?',
        kiiaLink: 'https://www.creativeshelter.be/kom-ik-in-aanmerking',
        title: 'Kom je in aanmerking?',
      },
      text: {
        first:
          'Het enige facturatieplatform dat je geld oplevert.<break/>Verdien meer als creatieve freelancer door jouw auteursrechten te verzilveren.',
        second: 'Je auteursrechten zijn goud waard.',
      },
    },
    testimonials: {
      indiana: {
        body: 'Het heeft even geduurd voor ik de tijd nam om me in te schrijven en alles in orde te brengen maar wat ben ik blij dat ik dit gedaan heb. Ondertussen is Creative Shelter een tabblad dat standaard openstaat en dat ik dagelijks gebruik. Heel leuk om te zien hoeveel winst je telkens extra hebt ten opzichte van een standaard factuur.',
        name: 'Indiana De Clerq',
        title: 'Meer winst ten opzichte van een standaard factuur',
      },
      joelle: {
        body: 'Zeer tevreden over Creative Shelter! 😊 Ze zijn bijzonder gebruiksvriendelijk, nauwkeurig en helder, bieden altijd een vriendelijke service, en een tijdige en effectieve opvolging. Ten zeerste aanbevolen!',
        name: 'Joëlle Batens',
        title: 'Gebruiksvriendelijk, nauwkeurig en helder',
      },
      toon: {
        body: 'Al +5 jaar een erg tevreden gebruiker van het Creative Shelter platform, een aanrader voor elke freelance creatieveling!',
        name: 'Toon Van Craeyevelt',
        title: 'Aanrader voor elke freelance creatieveling',
      },
    },
  },
};
