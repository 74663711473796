export const settings = {
  accountCompletionModal: {
    action: {
      complete: 'Compléter',
      next: 'Suivant',
    },
    description: "Remplissez d'abord quelques informations avant de pouvoir facturer.",
    financialInfo: {
      check: 'Vérifier',
      description: 'Entrez votre numéro IBAN ci-dessous.',
      error: "Une erreur s'est produite lors de la sauvegarde de votre numéro de compte IBAN.",
      tab: 'Coordonnées bancaires',
    },
    frameworkAgreement: {
      description: 'Lisez le contrat et donnez votre accord en saisissant "Lu et approuvé" dans le champ ci-dessous.',
      descriptionAddendum2024:
        "Depuis le début de la nouvelle année, la clé de distribution est passée à 60/40, revenus professionnels/droits d'auteur. Vous trouverez ci-dessous l'avenant à votre contrat-cadre avec Creative Shelter. En saisissant « Lu et approuvé » dans le champ ci-dessous, vous marquez votre accord et pouvez continuer à facturer.",
      error: "Une erreur s'est produite lors de la signature de votre contrat-cadre.",
      tab: 'Accord-cadre',
    },
    successModal: {
      action: "C'est parti !",
      description: 'Vous êtes maintenant prêt à envoyer votre première facture ou votre premier devis !',
      title: 'Compte complété',
    },
    title: 'Compléter votre compte',
    vatRegulation: {
      error: 'Un problème est survenu lors de la sauvegarde de votre régime de TVA.',
      tab: 'Régime de TVA',
    },
  },
  alerts: {
    brands: {
      successCreated: 'Votre marque de commerce a bien été créée !',
      successDeleted: 'Votre marque de commerce a bien été supprimée !',
      successFavorite: 'Votre nom commercial préféré a été modifié avec succès !',
      successUpdated: 'Votre marque de commerce a bien été modifiée !',
    },
    community: {
      errorProfilePictureDeleted:
        "Une erreur s'est produite lors de la suppression de votre photo de profil Community. Si le problème persiste, contactez service@creativeshelter.be.",
      errorProfilePictureTooLarge: "L'image que vous souhaitez télécharger ne doit pas dépasser 5 Mo.",
      errorProfilePictureUploaded:
        "Une erreur s'est produite lors du téléchargement de votre photo de profil Community. Si le problème persiste, contactez service@creativeshelter.be.",
      errorUpdated:
        "Une erreur s'est produite lors de la mise à jour de votre profil Community. Si le problème persiste, contactez service@creativeshelter.be.",
      successProfilePictureDeleted: 'Votre photo de profil Community a été supprimée !',
      successProfilePictureUploaded: 'Votre photo de profil Community a été modifiée !',
      successUpdated: 'Votre profil Community a été modifié !',
    },
    customConditions: {
      successDeleted: 'La condition a bien été supprimée !',
    },
    integrations: {
      successAccountantLinked: 'Votre comptable a été ajouté avec succès !',
      successAccountantUnlinked: 'Votre comptable a bien été supprimé !',
      successBillitPaired: 'Votre logiciel comptable a été ajouté avec succès !',
      successCashacaPaired: 'Cashaca a bien été connecté !',
      successForwardingEmailLinked: "L'adresse e-mail a été ajoutée avec succès !",
      successForwardingEmailUnlinked: "L'adresse e-mail a été supprimée avec succès !",
    },
    invitations: {
      clipboardError: "Copier dans le presse-papiers n'est pas pris en charge dans votre navigateur.",
    },
    numberingSeries: {
      duplicateFormat: 'Vous avez déjà utilisé le format de cette série de numérotation, veuillez en choisir un nouveau.',
      successCreated: 'Votre série de numérotation a été créée avec succès !',
      successDeleted: 'Votre série de numérotation a été supprimée avec succès !',
      successUpdated: 'Votre série de numérotation a été modifiée avec succès !',
    },
    successUpdated: 'Les paramètres ont bien été enregistrés !',
  },
  documents: {
    description: 'Un aperçu de tous les documents personnels.',
    headers: {
      name: 'Nom du fichier',
      type: 'Taper',
    },
    title: 'Documents',
  },
  edit: 'Modifier',
  fields: {
    accountantEmail: {
      label: 'Email de votre comptable',
      placeholder: 'Adresse mail',
    },
    activities: {
      other: {
        label: 'Entrez vos activités (créatives) supplémentaires ici',
      },
      primary: {
        label: 'Activité principale',
      },
      secondary: {
        label: 'Activités supplémentaires',
      },
    },
    annex: {
      label: 'Ajout',
      placeholder: 'Ajout',
    },
    box: {
      label: 'Boîte',
      placeholder: 'Boîte',
    },
    brandId: {
      label: 'Marque de commerce',
    },
    brandName: {
      label: 'Nom commercial',
      placeholder: 'Votre marque',
    },
    city: {
      label: 'Ville ou municipalité',
      placeholder: 'Ville ou municipalité',
    },
    color: {
      label: 'Couleur',
    },
    communityDescription: {
      label: 'Présentez-vous brièvement et/ou présentez vos activités',
    },
    communityLinks: {
      label: 'Liens',
      placeholders: {
        behance: 'https://www.behance.net/',
        facebook: 'https://www.facebook.com/',
        instagram: 'https://www.instagram.com/',
        linkedIn: 'https://www.linkedin.com/in/',
        website: 'https://',
      },
    },
    communityOpenToWork: {
      label: 'Je suis disponible pour des missions',
    },
    communityRegion: {
      label: 'Région',
      placeholder: 'Non spécifié',
    },
    companyName: {
      label: "Nom de l'entreprise",
    },
    currentPassword: {
      label: 'Mot de passe actuel',
    },
    email: {
      label: 'Adresse e-mail',
      placeholder: 'Adresse mail',
    },
    firstName: {
      label: 'Prénom',
      placeholder: 'Nom',
    },
    forwarding: {
      frequency: {
        label: 'Fréquence',
        options: {
          instant: 'Par facture ou note de crédit (.pdf/.xml)',
          monthly: 'Par mois (.zip)',
          quarterly: 'Par trimestre (.zip)',
        },
        tooltip:
          "Si vous avez saisi un logiciel de comptabilité, choisissez l'option permettant de transférer un fichier pdf et xml par facture ou note de crédit.",
      },
      platformEmail: {
        label: 'Adresse e-mail de votre compte Accountable',
      },
    },
    lang: {
      label: 'Langue',
    },
    lastName: {
      label: 'Nom de famille',
      placeholder: 'Nom de famille',
    },
    logo: {
      error: {
        delete: "Une erreur s'est produite lors de la suppression du logo. Réessayez à nouveau. ",
        upload: "Une erreur s'est produite lors du téléchargement du logo.",
      },
      label: 'Logo du nom commercial',
      tooltip:
        "Si vous sélectionnez ce nom commercial lors de la préparation d'un document, ce logo sera utilisé à la place du logo de votre nom commercial principal.",
    },
    mailText: {
      default:
        "Bonjour #NAME#<br/><br/>Les freelances sont là pour s'entraider et se soutenir. Et c’est précisément pour cette raison que j’aimerais aujourd’hui vous présenter l’outil Creative Shelter. J'en ai moi-même déjà beaucoup profité et gagné.<br/><br/>Avez-vous déjà entendu parler de Creative Shelter ? Eh bien, Creative Shelter propose une plateforme en ligne qui, grâce aux droits d'auteur, m'aide à gagner plus grâce à mes missions indépendantes. Rapide et facile et en plus légalement parfaitement en règle.<br/><br/>C'est peut-être aussi quelque chose pour vous ?<br/><br/>Cordialement,<br/>{{ firstName }} {{ lastName }}",
      label: 'Email',
      placeholder: 'Ecrivez votre message ici',
    },
    mainOccupation: {
      label: 'Activité indépendante',
      options: {
        '0': 'Activité complémentaire',
        '1': 'Activité principale',
      },
    },
    newPassword: {
      label: 'Nouveau mot de passe',
    },
    newPasswordConfirmation: {
      label: 'Confirmer le nouveau mot de passe',
    },
    number: {
      label: 'Numéro de maison',
      placeholder: '#',
    },
    numberingSeries: {
      format: {
        amountOfDigits: {
          label: 'Nombre de chiffres',
          option: '{{amountOfDigits}} chiffres',
        },
        lengthWarning: 'Nous recommandons de limiter la longueur du numéro à {{limit}} caractères.',
        nextNumber: 'Le numéro suivant de cette série est <strong>{{numéro}}</strong>.',
        prefixText: {
          label: 'Préfixe',
        },
        separator: {
          label: 'Séparateur',
          none: 'Aucun',
        },
        suffixText: {
          label: 'Suffixe',
        },
        useSuffix: 'Utiliser un suffixe',
        year: {
          label: 'Année/mois',
          options: {
            YY: 'Année court ({{yearMonth}})',
            YYMM: 'Année court + mois ({{yearMonth}})',
            YYYY: 'Année ({{yearMonth}})',
            YYYYMM: 'Année + mois ({{yearMonth}})',
            none: 'Aucun',
          },
        },
      },
      name: {
        label: 'Nom',
        placeholder: 'p.ex. Factures de vente',
        tooltip: 'Nommez votre série de numérotation, vous la reconnaîtrez ainsi rapidement.',
      },
      resetInterval: {
        description: 'Remettre le comptage à 1 automatiquement.',
        label: 'Redémarrer comptage',
        options: {
          monthly: 'Mensuellement',
          never: 'Jamais',
          yearly: 'Annuellement',
        },
      },
      startAt: {
        label: 'Premier numéro',
      },
      type: {
        label: 'Type',
        options: {
          both: 'Factures et notes de crédit',
          credit_notes: 'Notes de crédit',
          invoices: 'Factures',
        },
        tooltip:
          "Dans votre comptabilité, il existe deux  types de documents importants, vos factures ou vos notes de crédit. C'est à vous de choisir une série de numérotation qui couvre les deux, mais les diviser est également possible. A vous de choisir.",
      },
    },
    phone: {
      label: 'Numéro de téléphone portable/GSM',
      placeholder: '04 999 999 99',
    },
    street: {
      label: 'Nom de rue',
      placeholder: 'Nom de rue',
    },
    vatID: {
      label: 'Numéro de TVA',
      placeholder: 'BE1233456789',
    },
    zipcode: {
      label: 'Code postal',
      placeholder: 'Code postal',
    },
  },
  general: {
    customConditions: {
      create: 'Nouvelles conditions',
      delete: {
        action: 'Supprimer',
        description: 'Êtes-vous sûr de vouloir supprimer la condition générales sur mesure ({{name}}) ?',
        title: 'Supprimer la condition générale sur mesure',
      },
      description: 'Consultez ou créez ici vos conditions générales sur mesure',
      table: {
        actions: {
          creatingPdf: "Génération d'un PDF...",
          delete: 'Supprimer',
          title: 'Actions',
          view: 'Consulter',
        },
        name: 'Nom',
      },
      title: 'Conditions générales sur mesure',
      wizard: {
        already_agreement: {
          question: 'Avez-vous déjà convenu avec votre client de la manière dont votre travail peut être utilisé ?',
        },
        already_agreement_no: {
          question: "Pas de souci ! À l'aide des questions suivantes, vous décidez de ce que votre client peut faire de votre travail.",
        },
        already_agreement_no_paper: {
          question: "Pas de problème! A l'aide des questions suivantes, nous allons configurer vos questions.",
        },
        already_agreement_paper: {
          question: 'Avez-vous ces accords sur papier ?',
        },
        already_agreement_uploaded: {
          question:
            "Merci d'avoir téléchargé le contrat que vous avez avec votre client. Nous nous en occupons et nous vous revenons au plus vite !",
        },
        already_agreement_yes_paper: {
          question:
            "Parfait, car de bons accords font de bons amis. Indiquez dans le profil de votre client que vous avez déjà un contrat existant et envoyez-nous une copie par email (<a href='mailto:administratie@creativeshelter.be'>administratie@creativeshelter.be</a>) . Nous réviserons votre contrat et devrons le cosigner en tant que tierce partie.'",
        },
        common_answers: {
          close: "OK, fermer l'assistant de configuration",
          next: 'OK, question suivante',
          no: 'Non',
          ok: "D'accord",
          previous: 'Question précédente',
          start_questions: 'OK, démarrer les questions',
          yes: 'Oui',
        },
        modify_how: {
          options: {
            '0': "Adapter/modifier par l'ajout et/ou la suppression d'éléments",
            '1': 'Traduction/transfert',
          },
          question: 'De quelle façon votre travail peut-il être adapté ou modifié?',
        },
        overview: {
          error:
            "Une erreur s'est produite lors de la création de vos conditions générales sur mesure. Veuillez réessayer plus tard ou envoyez-nous un message.",
          finish: 'OK, créer mes conditions générales sur mesure.',
          name: {
            label:
              'Nommez ici vos conditions générales sur mesure. Utilisez p.ex. le nom de votre client ou encore un élément spécifique de vos CG sur mesure.',
            placeholder: 'Par exemple "Droit d\'utilisation Benelux 1 an"',
          },
          question: "Merci d'avoir répondu aux questions! En voici l'aperçu:",
        },
        reproduce_how: {
          options: {
            '0': "Par n'importe quel moyen, sous toutes les formes, par toutes les techniques et sur tous les supports",
            '1': 'Sous une forme spécifique',
            '2': 'Sous forme électronique sur support fixe',
            '3': 'Sous forme électronique sur support informatique',
          },
          other_placeholder: 'Spécifiez sous quelle forme',
          question: 'Par quels moyens votre travail peut-il être reproduit?',
        },
        reproduce_how_many: {
          options: {
            '0': 'Illimité',
            '1': 'Limité',
          },
          other_placeholder: "Specifiez le nombre d'exemplaires",
          question: "Reproduction à combien d'exemplaires?",
        },
        share_how: {
          options: {
            '0': 'Publication dans/sur',
            '1': 'Mise à disposition via ...',
            '2': 'Publication dans un ouvrage littéraire, un catalogue, ...',
            '3': 'En tant que matériel de promotion et pour usage commercial.',
          },
          other_placeholder: 'Spécifiez',
          question: 'Comment votre travail peut-il être publié?',
        },
        start: {
          description: "Suivez l'assistant de configuartion ci-dessous pour créer vos conditions générales sur mesure",
          ok: "OK, démarrer l'assistant de configuration",
          title: 'Créer vos conditions générales sur mesure',
        },
        thanks: {
          close: "Fermer l'assistant de configuration",
          explanation:
            "Créez votre facture et sélectionnez le nom de vos conditions générales sur mesure qui se trouvent dans le menu 'régimes droits d'auteur'. Vos CG sur mesure seront automatiquement annexées à la facture que nous enverrons à votre client. Les CG sur mesure sont reprises à l'article 8 (8.1 au 8.3). La mention des CG sur mesure est également reprise sur la facture dans le champ 'conditions particulières'.",
          question:
            'Nous créons vos conditions générales sur mesure. Celles-ci seront bientôt disponibles et vous pourrez les utiliser lors de la création de votre facture!',
          subtitle: 'Comment ça marche?',
        },
        transfer_copyright: {
          question: "Voulez-vous céder vos droits d'auteur à votre client pour ce travail?",
        },
        transfer_copyright_no: {
          question: "Parfait! L'étape suivante est de déterminer l'utilisation de votre travail par votre client.",
        },
        transfer_copyright_yes: {
          question:
            "Parfait! Il vous suffit de choisir le régime 'transfert des droits d'auteur' lors de la création de votre offre ou de votre facture.",
        },
        use_how: {
          multiple: 'plusieurs options possibles',
          options: {
            '0': 'Reproduire',
            '1': 'Publier',
            '2': 'Adapter/modifier',
          },
          question: 'Comment votre client peut-il utiliser votre travail?',
        },
        use_how_long: {
          options: {
            '0': 'Durée totale',
            '1': 'Autre durée',
          },
          other_placeholder: 'Spécifiez la durée',
          question: 'Pour quelle durée?',
        },
        use_where: {
          options: {
            '0': 'Belgique',
            '1': 'Europe',
            '2': 'Le monde entier',
            '3': 'Autre(s)',
          },
          other_placeholder: 'p.ex. Benelux',
          question: 'Où est-ce que votre client peut utiliser votre travail?',
        },
      },
    },
    description: 'Modifiez la mise en page et le fonctionnement de votre plateforme Craft.',
    email: {
      bcc: {
        description:
          'Cela signifie que vous êtes en copie cachée de tous les e-mails que nous envoyons à vos clients, des nouvelles offres, factures et notes de crédit et rappels de paiement des factures.',
        title: 'Mettez-moi en Cci de tous les e-mails de mes clients',
      },
      creationReminder: {
        description: "N'envoyez plus de facture trop tard grâce à ce rappel.",
        options: {
          '0': 'Pas de rappel',
          '1': 'Le dernier jour du mois',
          '2': '1 semaine avant la fin du mois',
          '3': '2 semaines avant la fin du mois',
        },
        title: 'Rappel pour la création de factures',
      },
      description: 'Choisissez ici les e-mails et notifications que vous souhaitez recevoir',
      followUpEnabled: {
        description: "Recevez une notification lorsqu'il y a une mise à jour sur le suivi de paiement pour une facture expirée",
        title: 'Mise à jour des suivis de paiements',
      },
      invitationReminder: {
        description: "Recevoir un rappel quand votre collègue freelance invité reçoit également un rappel d'enregistrement.",
        title: 'Rappel des invitations envoyées',
      },
      nettoSaved: {
        description:
          "Obtenez un aperçu de vos économies mensuelles en utilisant Creative Shelter. De cette façon, vous pouvez voir en noir et blanc vos économies supplémentaires en rachetant vos droits d'auteur.",
        title: 'E-mail mensuel sur les économies nettes',
      },
      title: 'E-mails et notifications',
    },
    numberingSeries: {
      add: 'Nouvelle série de numérotation',
      delete: {
        description: 'Êtes-vous certain(e) de vouloir supprimer "{{numberingSeries}}" ?',
        title: 'Supprimer',
      },
      description:
        'Configuration de numérotation automatique des factures et avoirs. Des questions, plus d&#39;informations peuvent être trouvées <a href="https://knowledge-creativeshelter-be.translate.goog/wat-is-een-nummeringreeks-en-hoe-stel-ik-deze-in?_x_tr_sl=nl&_x_tr_tl=fr&_x_tr_hl=nl&_x_tr_pto=wapp" target="_blank">ici</a> .',
      edit: {
        format: 'Format/modifier les options',
        name: 'Modifier nom/type',
      },
      headers: {
        name: 'Nom',
        nextNumber: 'Numéro suivant',
        type: 'Type ',
      },
      title: 'Série de numérotation',
    },
    theme: {
      description: 'Choisissez le thème de votre plateforme Craft',
      title: 'Thème de la plateforme',
    },
    themeInvoice: {
      colorTheme: {
        helpText: 'Choisissez une combinaison de couleurs pour les documents destinés à vos clients.',
        subtitle: 'Thème de couleur',
      },
      description: 'Choisissez le thème des factures de votre client.',
      logo: {
        helpText: 'Téléchargez le logo de votre entreprise individuelle ici.',
        subtitle: 'Logo',
      },
      title: 'Thème de la facture',
    },
    title: 'Paramètres',
  },
  integrations: {
    accountant: {
      current: 'Le comptable suivant a été ajouté à votre compte :',
      description:
        'L\'ajout de votre comptable lui donne accès à vos factures et documents via <a href=https://api.creativeshelter.be/accountants/login target="_blank">\n la plateforme Credit </a>\n  de Creative Shelter',
      pair: 'Ajouter',
      requested: "Le comptable mentionné ci-dessous a demandé l'accès à votre compte:",
      title: 'Donnez accès à votre comptable',
      unpair: 'Supprimer',
    },
    description: 'Liez ici votre compte Creative Shelter aux services externes.',
    externalSystems: {
      description: 'En vous connectant à un système externe, vous étendez la fonctionnalité de votre compte à celle du système externe.',
      pairWith: 'Lien avec {{ system }}',
      paired: 'Lié',
      systems: {
        billit: {
          description:
            'Créez-vous également des factures non créatives en plus des factures créatives ? Cette intégration permet de créer et de lier facilement ces factures non créatives à votre compte Creative Shelter.',
          name: 'Billit',
        },
        cashaca: {
          description:
            "Cashaca simplifie la comptabilité. Idéal pour les indépendants réalisant un chiffre d'affaires inférieur à 500 000€.",
          modal: {
            apiToken: 'Cashaca code API',
            description:
              'Complétez votre code API personnel ici.<br/>Vous n\'avez pas encore de compte Cashaca? <a href="https://app.cashaca.be/gebruikers/registreer?utm_source=creativeshelter" target="_blank">Enregistrez-vous ici</a>.',
          },
          name: 'Cashaca',
        },
      },
      title: 'Lien vers des systèmes externes',
    },
    forwarding: {
      deleteModal: {
        confirm: "Oui, j'en suis sûr.",
        description: 'Êtes-vous sûr de ne plus vouloir transmettre vos factures et notes de crédit à {{email}} ?',
        title: 'Arrêter le transfert automatique',
      },
      description: 'Transférez automatiquement vos factures et notes de crédit à votre comptable ou à votre logiciel de comptabilité.',
      title: 'Transfert automatique',
    },
    pair: 'Connecter',
    processing: 'Nous récupérons des données personnelles',
    title: 'Comptabilité & Intégrations',
  },
  invitations: {
    description: 'Affichez et gérez vos invitations.',
    discountsCard: {
      active: 'Actif',
      commissionSaved: '<strong>{{amount}}</strong> épargné',
      discount: '{{percentage}} réduction',
      endedAt: 'Terminé le {{endDate}}',
      expired: 'Expiré',
      invite: "Invitez quelqu'un !",
      noDiscounts: "Vous n'avez pas encore reçu de récompenses (pour l'instant).",
      startedAt: 'Commencé le {{startDate}}',
      title: 'Récompenses',
    },
    intro: {
      disclaimer:
        "* Cette réduction est valable un an et prend effet dès qu'une facture est envoyée par votre collègue freelance, dans les 30 jours suivant la création de son compte. Cela inclut la personne que vous avez invitée, le plan Premium au prix Classic, qui sera automatiquement activé.",
      paragraphs: [
        {
          body: 'Vous êtes fan et il vous arrive de faire de la publicité pour nous. Formidable, cela mérite une récompense. Non pas en vous offrant des fleurs mais en vous accordant une réduction.',
          subtitle: "Invitez quelqu'un à facturer par notre intermédiaire !",
        },
        {
          body: "Cela représente 25 % de votre rémunération actuelle. Un quart donc, et c'est cadeau ! Plus vous invitez de personnes, plus vous bénéficiez de remises ! Alors, invitez 4 nouveaux utilisateurs qui facturent. Vous ne payerez rien du tout car vous facturerez ENTIÈREMENT GRATUITEMENT.",
          subtitle: 'Comment fonctionne cette remise ?',
        },
        {
          body: "Nous vous chouchoutons, et nous accueillons votre collègue freelance à bras ouverts par le biais d'un plan Premium au prix Classic pendant un an. De cette façon, vous profiterez tous les deux de l'année à venir de manière extra.",
          subtitle: 'La personne invitée reçoit également quelque chose ?',
        },
      ],
      title: "Envie d'une réduction et de gagner encore plus ?",
    },
    invitationsCard: {
      noInvitations: "Vous n'avez pas encore envoyé d'invitations.",
      noMoreInvitations: "Vous n'avez plus d'invitations en attente.",
      status: {
        accepted: 'Enregistré',
        declined: 'Refusé',
        pending: 'En attente',
      },
      title: 'Invitations',
    },
    invite: {
      error: {
        userAlreadyExists: "L'utilisateur que vous souhaitez inviter, facture déjà via la plateforme Craft.",
      },
      success: "L'invitation a été envoyée avec succès et est en route vers {{ name }}",
      title: "Inviter quelqu'un",
    },
    steps: {
      copied: 'Copié',
      sendInvitationButton: "Bénéficiez d'une remise de 25 %",
      stepOne: "Vous invitez une personne éligible à l'aide de votre code personnel.",
      stepThree: "Félicitations, c'est l'heure des 25 % de remise ! Un an. 365 jours d'économies.",
      stepTwo: 'Votre collègue freelance crée un compte et facture dans les 30 jours.',
      title: 'Comment obtenir 25% de réduction ?',
    },
    title: 'Invitations',
  },
  nps: {
    sendFeedback: 'Envoyer votre avis',
    titleStepOne: "Dans quelle mesure recommanderais-tu Creative Shelter à d'autres freelances?",
    titleStepThree: 'Merci! Nous allons se mettre au travail avec vos commentaires!',
    titleStepTwo: 'Que pourrions-nous encore faire pour améliorer nos services?',
  },
  paymentPlan: {
    changePlanModal: {
      confirm: 'Modifier votre plan',
      descriptionDowngrade:
        "Êtes-vous sûr de vouloir réduire votre {{currentPlan}}\n à {{newPlan}}\n ? Des doutes par rapport aux pans tarifaires et leurs rémunérations respectives ? N'hésitez pas à nous contacter via <a href='mailto:service@creativeshelter.be'> service@creativeshelter.be</a>, nous nous ferons un plaisir de vous aider.",
      descriptionUpgrade: 'Envie de plus ? Passez de {{currentPlan}} à {{newPlan}} !',
      title: 'Modifier le plan',
    },
    commission: {
      currentCommission: 'Rémunération actuelle',
      description: {
        creative:
          '<a href="https://knowledge.creativeshelter.be/een-creatieve-prestatie-wat-houdt-dit-in" target="_blank">Performances créatives</a> à {{percentage}} %',
        creativeFree:
          '<a href="https://knowledge.creativeshelter.be/een-creatieve-prestatie-wat-houdt-dit-in" target="_blank">Performances créatives</a> gratuites, woo-hoo !',
        nonCreative:
          '<a href="https://knowledge.creativeshelter.be/wat-factureer-ik-als-geen-creatieve-prestatie" target="_blank">Performances non créatives</a> à {{percentage}} %',
        nonCreativeFree:
          '<a href="https://knowledge.creativeshelter.be/wat-factureer-ik-als-geen-creatieve-prestatie" target="_blank">Performances non créatives</a> gratuites !',
        subcontractingFree:
          '<a href="https://knowledge.creativeshelter.be/onderaanneming-wat-is-dat-precies" target="_blank">Sous-traitance</a> gratuite',
      },
      details: {
        baseCommission: 'Rémunération de base',
        bracket: {
          '1': 'Première tranche',
          '2': 'Deuxième tranche',
          '3': 'Troisième tranche',
        },
        invite: 'Envie de plus de réduction? Invitez quelqu’un!',
        referral: 'Remise de parrainage (-{{montant}}%)',
        toggle: 'Détails',
        total: 'Nouvelle rémunération',
      },
      disclaimer: {
        adminOverride: "Votre rémunération diffère du tarif standard ; le principe de graduation ne s'applique pas à vous.",
        adminOverrideFixed: "Votre rémunération diffère du tarif standard ; le principe de graduation ne s'applique pas à vous.",
        copyrightLimitExceeded:
          "Vous avez dépassé votre limite de droits d'auteur pour cette année. Toutes vos performances étant désormais des revenus professionnels, vous pouvez les facturer gratuitement jusqu'à la fin de l'année.",
      },
      planName: {
        '4': 'Early adopter',
        '5': 'Plan classique',
        '6': 'Plan Premium',
      },
    },
    earlyAdopter: {
      description:
        'En tant que Early Adopter, vous bénéficierez de toutes les fonctionnalités de notre plan Premium au prix le plus abordable.',
      features: [
        {
          text: "Paiement immédiat des droits d'auteur",
        },
        {
          text: 'Choix du délai de paiement',
        },
        {
          text: 'Suivi des factures impayées via huissier inclus',
        },
        {
          text: 'Conditions générales de vente sur mesure',
        },
        {
          text: 'Lien avec des outils externes',
          tooltip: 'Billit et/ou Cashaca',
        },
      ],
      title: 'Plan tarifaire',
    },
    freeInvitationCTA: {
      text_one:
        'Votre promo est toujours active {{count}} jour. Ainsi, aujourd’hui, vous facturez gratuitement comme le tout dernier jour.',
      text_other: 'Votre promo est toujours active {{count}} jours. Vous facturez donc entièrement gratuitement.',
    },
    header: {
      description: 'Consultez la rémunération que vous payez ici ou modifiez votre forfait',
      title: 'Plan tarifaire',
    },
    invitationCTA: {
      link: 'Cliquez ici pour envoyer une invitation.',
      text: "Bénéficiez d'une réduction de 25% sur votre rémunération pour chaque collègue freelance que vous invitez ! ",
    },
    picker: {
      commissionText: 'par performance créative',
      contact: 'Contactez-nous',
      current: 'Plan actuel',
      disclaimer: "Tous les tarifs s'entendent hors remise",
      features: {
        '5': [
          {
            text: 'Accès à la plateforme Craft',
          },
          {
            text: '<a href="https://knowledge.creativeshelter.be/wat-als-mijn-klant-opdrachtgever-nog-niet-betaald-heeft-op-de-vervaldag" target="_blank">E-mails de rappel automatiques </a> pour les factures impayées',
            tooltip: "Expédié 2 et 8 jours après la date d'échéance de votre facture",
          },
          {
            text: 'Paiement immédiat de votre facture dès que votre client a payé',
            tooltip: "Vos revenus professionnels et vos droits d'auteur",
          },
          {
            text: 'Envoyez vos factures<a href="https://knowledge.creativeshelter.be/kan-ik-mijn-facturen-automatisch-doorsturen-naar-mijn-accountant" target="_blank"> automatiquement</a> à votre comptable/logiciel comptable',
            tooltip: 'Sous forme de fichier PDF ou XML',
          },
          {
            text: 'Déclaration du précompte mobilier et établissement<a href="https://knowledge.creativeshelter.be/wat-is-de-fiscale-fiche-281.45-1" target="_blank"> fiche fiscale annuelle obligatoire 281,45</a>',
          },
          {
            text: 'Assistance juridique et assurance responsabilité professionnelle gratuites',
            tooltip:
              'Plus d\'info à ce sujet <a href="https://knowledge.creativeshelter.be/een-ba-verzekering-bescherm-jezelf-extra-dankzij-creative-shelter" target="_blank"> ici </a> !',
          },
        ],
        '6': [
          {
            text: 'Tous les avantages du plan Classic',
          },
          {
            text: '<a href="https://knowledge.creativeshelter.be/wat-als-mijn-klant-opdrachtgever-nog-niet-betaald-heeft-op-de-vervaldag" target="_blank">Suivi supplémentaire des factures impayées</a> jusqu\'au huissier inclus',
            tooltip: "L'huissier n'est nommé qu'en Belgique",
          },
          {
            link: '/settings/general#custom-conditions',
            text: 'Conditions générales sur mesure',
          },
          {
            text: 'Lien avec des outils externes',
            tooltip: 'Billit et/ou Cashaca',
          },
          {
            text: 'Licence Billit gratuite',
            tooltip: "Si vous n'avez pas encore de licence payante",
          },
          {
            text: 'Support prioritaire',
          },
        ],
        label: 'Pour ce plan, vous bénéficiez des avantages suivants :',
      },
      hideDetails: 'Masquer les détails',
      select: 'Choisis ce plan',
      showDetails: 'Afficher les détails',
      subtitle: {
        '5': "Pour ceux qui se contentent de l'essentiel",
        '6': 'Pour ceux qui veulent le meilleur service',
      },
    },
    plans: {
      sectionDescription: 'Un aperçu des différents plans de paiement et des avantages et rémunérations.',
      sectionTitle: 'Plans tarifaires',
    },
    progress: {
      disclaimer: 'La progression est réinitialisée chaque année le {{startDate}}. La compensation ci-dessus est sans aucune réduction.',
      title: 'Total facturé',
      tooltip: 'La sous-traitance n&#39;est pas ajoutée au montant total.',
      zeroProgress: "Rien pour l'instant, alors allez-y, facturez simplement pour remplir votre comptoir !",
    },
    requestPlanChangeModal: {
      cancel: 'Garder le plan',
      confirmationDescription: 'Nous vous contacterons dès que possible pour examiner votre changement de plan.',
      confirmationDismiss: 'Compris !',
      confirmationTitle: 'Demande envoyée',
      description:
        'Vous utilisez actuellement le {{currentPlan}} à un tarif différent (moins cher). Si vous changez de plan, cet avantage sera perdu. Voulez-vous vraiment changer votre plan en {{newPlan}} ? Alors demandez un changement, afin que nous puissions examiner cela plus en détail avec vous.',
      requestChange: 'Demander un changement',
      title: 'Modifier le plan',
    },
    tags: {
      PremiumForClassic: 'Premium au tarif Classic',
      PremiumForFree: '{{daysValid}} jours gratuits',
      copyrightLimitExceeded: 'Rémunération ajustée',
      customPercentage: 'Rémunération ajustée',
      discount: 'Réduction de parrainage',
      mostPopular: 'Plan le plus populaire',
    },
  },
  profile: {
    activities: {
      description: 'Indiquez ici le secteur créatif dans lequel vous êtes actif.',
      modal: {
        description:
          'Avez-vous vérifié si vos activités sont toujours correctes ? Vous pouvez toujours trouver ces paramètres sous votre profil.',
        next: 'Suivant',
        previous: 'Précédent',
        title: 'Confirmer les activités',
      },
      title: 'Activités',
      warnings: {
        stylingAndInterior: {
          body: 'À partir de 2024, certaines prestations créatives en matière de créations d\'intérieurs ne sont plus éligibles à une compensation en droits d\'auteur. L\'interprétation de la loi est plus stricte que la loi en soi. Vous doutez si votre prestation est éligible ? <a href="mailto:administratie@creativeshelter.be?subject=Komen mijn prestaties voor styling/interieur nog in aanmerking?" target="_blank">Envoyez-nous un mail</a> et nous reviendrons vers vous.\n\nVous restez éligible à d\'autres activités créatives. ',
          title: "Important ! Styliste/créateur d'intérieurs, lisez la suite.",
        },
      },
    },
    brands: {
      add: 'Ajouter une marque de commerce',
      delete: {
        description: 'Voulez-vous vraiment supprimer "{{brand}}" ?',
        title: 'Supprimer la marque de commerce',
      },
      description: 'Afficher, créer et modifier vos marques de commerce',
      edit: 'Personnaliser la marque de commerce',
      favorite: {
        markAsFavorite: 'Indiquer comme favori',
        removeFavorite: 'Supprimer le favori',
      },
      headers: {
        color: 'Couleur de la marque de commerce',
        email: 'Adresse mail',
        logo: 'Logo',
        name: 'Nom de votre marque de commerce',
      },
      logoAdd: 'Ajoutez votre logo',
      logoEdit: 'Modifiez votre logo',
      mainBrand: 'Marque de commerce principale',
      title: 'Marques de commerce',
    },
    community: {
      blocker:
        "Êtes-vous sûr de vouloir quitter la page ? Les modifications apportées à votre profil Community n'ont pas encore été enregistrées.",
      communityHidden:
        'Votre accès à la Craft Community a été désactivé. Pour plus d\'infos, envoyez-nous <a href="mailto:admin@creativeshelter.be?subject= Geblokkeerde toegang Craft Community" target="_blank"> un e-mail</a>.',
      description: 'Modifiez votre profil Community ici',
      disableCommunityProfile: 'Supprimez votre profil Community',
      enableCommunityProfile: 'Rendre votre profil public dans Community',
      title: 'Community',
    },
    description: 'Modifier les paramètres de votre compte Creative Shelter',
    financial: {
      bank: 'Nom banque',
      bic: 'BIC/SWIFT',
      description: 'Vos coordonnées bancaires et financières',
      edit: 'Modifier',
      iban: 'IBAN',
      title: 'Informations financières',
    },
    info: {
      language: {
        en: 'anglais',
        fr: 'français',
        nl: 'néerlandais',
      },
      subsections: {
        address: "Données d'adresse",
        personal: 'Informations personnelles',
      },
      title: 'Informations profile',
    },
    password: {
      edit: 'Changer le mot de passe',
      title: 'Changer le mot de passe',
    },
    title: 'Profil',
    vatRegulation: {
      description: 'Vous trouverez ci-dessous des informations supplémentaires sur votre régime de TVA actuel. ',
      edit: 'Adapter',
      help: 'Indiquez le régime de TVA que vous utilisez. Cela permet de déterminer si la TVA sera facturée sur vos factures. Les petites entreprises dont le chiffre d\'affaires est inférieur à 25 000€ ont droit au régime d\'exemption et peuvent en faire la demande elles-mêmes. Pour plus d\'informations, consultez notre <a href="https://knowledge-creativeshelter-be.translate.goog/wat-is-de-btw-vrijstellingsregeling-en-bestaan-er-nog-andere-regelingen?_x_tr_sl=nl&_x_tr_tl=fr&_x_tr_hl=en-US&_x_tr_pto=wapp" target="_blank">base de connaissances.</a>',
      info: {
        none: "Vous n'avez pas encore indiqué votre régime de TVA.",
        vatExempt:
          "Vous faites appel au <strong>régime d'exemption de TVA</strong>. Les factures entre vous et Creative Shelter sont établies sans TVA. Suivez les indications ci-dessous pour savoir si vous êtes proche de la limite.",
        vatNormal:
          'Vous utilisez le <strong>régime normal de TVA</strong>. Les factures entre vous et Creative Shelter sont établies avec TVA.',
      },
      title: 'Statut de la TVA',
      toggle: {
        vatExempt: 'Exonéré de TVA',
        vatNormal: 'Soumis à la TVA',
      },
      tooltip: "Si votre chiffre d'affaires dépasse 25 000€, vous n'êtes plus autorisé à bénéficier du régime d'exonération de TVA.",
      totalRevenueInYear: "Chiffre d'affaires total {{year }}",
    },
  },
};
