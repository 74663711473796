import * as amplitude from '@amplitude/analytics-browser';
import { useId } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useCheckEmailAddressInUse, useCreateUncompletedRegistration } from '~/api/auth';
import { Description, Form, FormField, FormInput, FormValidationError, Label, LabelDescriptionGroup } from '~/components/Form';
import { Redirect } from '~/components/Functional';
import { Alert, AlertDescription, AlertTitle, Button, ButtonGroup, ExternalLink, Flex } from '~/components/UI';
import { AmplitudeEventName } from '~/constants/amplitude';
import { EMAIL } from '~/constants/regex';
import { routes } from '~/providers/RouterProvider/router.routes';

import { AuthLayoutContent } from '../AuthLayout/AuthLayoutContent';
import { AuthLayoutTitleGroup } from '../AuthLayout/AuthLayoutTitleGroup';
import { PreviousStepButton } from './PreviousStepButton';
import { useRegistrationContext } from './Registration.context';
import { RegistrationAddReferralCodeButton } from './RegistrationReferralCode/RegistrationAddReferralCodeButton';
import { RegistrationReferralCode } from './RegistrationReferralCode/RegistrationReferralCode';
import { checkVatInformationStepCompleted } from './utils';

export const RegistrationAccountInformation = () => {
  const createUncompletedRegistrationMutation = useCreateUncompletedRegistration();
  const checkEmailAddressInUseMutation = useCheckEmailAddressInUse();

  const { accountInformation, hadReferralCode, setAccountInformation, vatInformation } = useRegistrationContext();

  const { control, formState, handleSubmit } = useForm({
    defaultValues: accountInformation,
  });
  const formId = useId();

  const navigate = useNavigate();
  const { t } = useTranslation(['auth', 'validation']);

  if (!checkVatInformationStepCompleted(vatInformation)) return <Redirect to={routes.register} />;

  const onSubmit = handleSubmit((data) => {
    setAccountInformation(data);

    createUncompletedRegistrationMutation.mutate(
      {
        firstName: vatInformation.founder?.firstName ?? '',
        lastName: vatInformation.founder?.lastName ?? vatInformation.companyName ?? '',
        emailAddress: data.emailAddress,
        phoneNumber: data.phoneNumber,
      },
      {
        onSettled: () => {
          amplitude.setUserId(data.emailAddress);
          amplitude.track(AmplitudeEventName.RegistrationStep2Completed);

          navigate(routes.registerBusinessInformation);
        },
      },
    );
  });

  return (
    <AuthLayoutContent>
      <AuthLayoutTitleGroup
        pill={t('auth:registrationPage.common.header.pill')}
        subtitle={t('auth:registrationPage.stepAccountInformation.header.subtitle')}
        title={t('auth:registrationPage.stepAccountInformation.header.title', {
          name: vatInformation.founder?.firstName ?? vatInformation.companyName ?? '',
        })}
      />

      <Flex autoAnimate direction="column" gap="normal">
        {!vatInformation.hasEligibleNacebelCodes && (
          <Alert variant="warning">
            <AlertTitle>{t('auth:registrationPage.stepAccountInformation.alertNoElibibleNacebelCodes.title')}</AlertTitle>
            <AlertDescription>{t('auth:registrationPage.stepAccountInformation.alertNoElibibleNacebelCodes.description')}</AlertDescription>
          </Alert>
        )}

        <Form id={formId} onSubmit={onSubmit}>
          <Flex direction="column" gap="normal">
            <FormField
              control={control}
              name="emailAddress"
              rules={{
                required: true,
                maxLength: 255,
                pattern: EMAIL,
                validate: {
                  unique: async (value) => {
                    try {
                      const { inUse } = await checkEmailAddressInUseMutation.mutateAsync(value.trim());
                      return !inUse;
                    } catch {
                      return false;
                    }
                  },
                },
              }}
            >
              <Label>{t('auth:registrationPage.stepAccountInformation.form.emailField.label')}</Label>
              <FormInput autoFocus={!accountInformation.emailAddress} type="email" />
              <FormValidationError
                maxLength={t('validation:maxLength', {
                  attribute: t('auth:registrationPage.stepAccountInformation.form.emailField.label'),
                  max: 255,
                })}
                pattern={t('validation:email.invalid')}
                required={t('validation:required')}
                validate-unique={
                  checkEmailAddressInUseMutation.isError ? (
                    <Trans
                      components={{ email: <ExternalLink /> }}
                      i18nKey="auth:registrationPage.stepAccountInformation.form.emailField.errors.generic"
                    />
                  ) : (
                    t('auth:registrationPage.stepAccountInformation.form.emailField.errors.exists')
                  )
                }
              />
            </FormField>

            <FormField control={control} name="password" rules={{ required: true, minLength: 8, maxLength: 255 }}>
              <LabelDescriptionGroup>
                <Label>{t('auth:registrationPage.stepAccountInformation.form.passwordField.label')}</Label>
                <Description>{t('auth:registrationPage.stepAccountInformation.form.passwordField.description')}</Description>
              </LabelDescriptionGroup>
              <FormInput type="password" />
              <FormValidationError
                maxLength={t('validation:maxLength', {
                  attribute: t('auth:registrationPage.stepAccountInformation.form.passwordField.label'),
                  max: 255,
                })}
                minLength={t('validation:minLength', {
                  attribute: t('auth:registrationPage.stepAccountInformation.form.passwordField.label'),
                  min: 8,
                })}
                required={t('validation:required')}
              />
            </FormField>

            <FormField control={control} name="phoneNumber" rules={{ required: true }}>
              <LabelDescriptionGroup>
                <Label>{t('auth:registrationPage.stepAccountInformation.form.phoneNumberField.label')}</Label>
              </LabelDescriptionGroup>
              <FormInput type="tel" />
              <FormValidationError required={t('validation:required')} />
            </FormField>
          </Flex>
        </Form>

        <RegistrationReferralCode />

        <Flex direction="column" gap="small">
          <ButtonGroup wrapReverse>
            <Button form={formId} loading={formState.isValidating} type="submit">
              {t('auth:registrationPage.stepAccountInformation.form.submitButton')}
            </Button>

            {hadReferralCode && <RegistrationAddReferralCodeButton />}
          </ButtonGroup>

          <PreviousStepButton to="vatNumber" />
        </Flex>
      </Flex>
    </AuthLayoutContent>
  );
};
